/* global ajax_object */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import { log } from 'debug';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Convert mili seconds to minutes and seconds
 */
function millisToMinutesAndSeconds(millis) {
  let minutes = Math.floor(millis / 60000);
  let seconds = Math.floor((millis % 60000) / 1000);
  return (
    minutes +
    ' min ' +
    (seconds != 0 ? (seconds < 10 ? '0' : '') + seconds + ' sec' : '')
  );
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Get spotify episodes ----- <div id="spotifyWrapper" class="spotify-list"></div>
   */
  let spotifyWrapper = $('#spotifyWrapper');
  if (
    ajax_object.spotify_token &&
    ajax_object.spotify_show &&
    spotifyWrapper.length > 0
  ) {
    $.ajax({
      url: `https://api.spotify.com/v1/shows/${ajax_object.spotify_show}/episodes?market=US&limit=3`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${ajax_object.spotify_token}`,
      },
      beforeSend: function () {
        spotifyWrapper.addClass('is-loading');
      },
      success: function (data) {
        spotifyWrapper.removeClass('is-loading');
        let resources = '';
        if (data) {
          resources =
            data.items &&
            data.items.map((item) => {
              let url =
                item.external_urls.spotify ||
                `https://open.spotify.com/episode/${item.id}`;
              let itemDate = new Date(item.release_date),
                month = itemDate.getMonth(),
                year = itemDate.getFullYear();

              let durection = item.duration_ms
                ? millisToMinutesAndSeconds(item.duration_ms)
                : 'no time';

              return `
              <div class='item'>
                <a href='${url}' target='_blank'>
                  <h4 class='item__title'>${item.name || ''}</h4>
                  <div class='item__desc'>${item.description || ''}</div>
                  <div class='item__date'>
                    ${monthNames[month]} ${year} - ${durection}
                  </div>
                </a>
              </div>
              `;
            });
        } else {
          resources = '<h2>Episodes not found</h2>';
        }
        spotifyWrapper.html(resources);
      },
      error: function (err) {
        console.log(err);
      },
    });
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // let apiKey = 'AIzaSyD9HtfBPHaQek18A5Jm4elGL3sNhZjcWx8'; // Замените на ваш YouTube API ключ
  // let playlistId = 'PLjeeBMoqnCEHTDX2sawzxBd0gGqwrfYKr'; // Замените на идентификатор вашего YouTube плейлиста
  // let apiUrl =
  //   'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=12&playlistId=' +
  //   playlistId +
  //   '&key=' +
  //   apiKey;
  //
  // let addedVideos = []; // Массив для отслеживания уже добавленных видео
  //
  // $.ajax({
  //   url: apiUrl,
  //   method: 'GET',
  //   dataType: 'json',
  //   success: function (response) {
  //     let videos = response.items;
  //
  //     $.each(videos, function (index, video) {
  //       let videoTitle = video.snippet.title;
  //       let videoThumbnail = video.snippet.thumbnails.medium.url;
  //       let videoId = video.snippet.resourceId.videoId;
  //
  //       // Проверка на дублирование видео
  //       if (!addedVideos.includes(videoId)) {
  //         // Выводите полученные данные в нужном формате (например, использовать в HTML слайдера)
  //         let slideHtml =
  //           '<div class="slide">' +
  //           '<h3>' +
  //           videoTitle +
  //           '</h3>' +
  //           '<a href="https://www.youtube.com/watch?v=' +
  //           videoId +
  //           '" target="_blank"><img src="' +
  //           videoThumbnail +
  //           '"></a>' +
  //           '</div>';
  //
  //         $('.slider').append(slideHtml);
  //
  //         addedVideos.push(videoId); // Добавление идентификатора видео в массив уже добавленных видео
  //       }
  //     });
  //   },
  //   error: function () {
  //     console.log('Ошибка при получении данных из YouTube API.');
  //   },
  // });

  $('.podcast-video').slick({
    arrows: true,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 0,
    responsive: [
      {
        breakpoint: 1110,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});

$('.featured-nav a').on('click', function () {
  var section = $('#featured');
  var offset = -250;
  var targetOffset = section.offset().top + offset;
  $('html, body').animate(
    {
      scrollTop: targetOffset,
    },
    'fast'
  );
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
